import React from "react"
import Layout from "../components/layout"

export default function Contact() {
  return (
    <Layout>
      <div style={{ color: `teal` }}>
        <h1>Contact me!</h1>
        <p>Such wow. Very React.</p>
      </div>
    </Layout>
  )
}